import React from "react";
import stl from "./bottomNotice.module.scss";
import BottomNoticeClient from "./BottomNoticeClient";
import classnames from "classnames";
export interface IProps {
    page_name: string;
    H5Notice: IH5Notice;
}

interface IH5Notice {
    background_image?: string;
    backgroundColor: string;
    ad_content?: string;
    visible: string;
    hide_pages: any[];
    pages: number[];
}

const BottomNotice: React.FC<IProps> = (props) => {
    const {
        background_image = "",
        backgroundColor,
        ad_content = "",
        visible,
    } = props.H5Notice;
    return (
        ad_content &&
        visible !== "hidden" && (
            <BottomNoticeClient {...props}>
                <div
                    className={classnames(
                        stl["bottom-notice-container"],
                        stl["bottom-notice-bg"],
                    )}
                    id="bottom-notice-container"
                    style={{
                        background: backgroundColor
                            ? backgroundColor
                            : background_image
                              ? `url(${background_image}) no-repeat center center`
                              : "#000",
                        backgroundSize: "auto 100%",
                    }}
                >
                    <div
                        className="ad-content"
                        dangerouslySetInnerHTML={{ __html: `${ad_content}` }}
                    ></div>
                </div>
            </BottomNoticeClient>
        )
    );
};

export default BottomNotice;
