import React from "react";
import stl from "./bubble.module.scss";
import { ICircle } from "@/store/modules/pageLayout";
import { ICmsProps } from "../Announcement/interface";
import BubbleClient from "./BubbleClient";
import FmLink from "@/ui-component/basic/FmLink";
import FmImage from "@/ui-component/basic/FmImage";
import FmIcon from "@/ui-component/basic/FmIcon";
export interface IProps extends ICmsProps {
    circle: ICircle;
}

const Bubble: React.FC<IProps> = (props) => {
    const { circle } = props;
    return (
        <div className={stl["bubble"]}>
            <BubbleClient {...props}>
                <FmLink href={circle?.href} className="bubble2_03">
                    <FmImage image={circle?.img} alt="" />
                </FmLink>
                <FmIcon icon="icon--close" />
            </BubbleClient>
        </div>
    );
};

export default Bubble;
