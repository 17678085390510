import React from "react";
import classnames from "classnames";
import stl from "../styles/notice.module.scss";
import { INoticeProps } from "..";

interface ITopItem {
    ad_content: string;
    has_skip_btn: boolean;
    skip_link: string;
    skip_content: string;
}
export const TopNoticeContent: React.FC<React.PropsWithChildren<ITopItem>> = (
    props,
) => {
    return (
        <div className={classnames("default_layout", stl["carousel-item"])}>
            <div
                className={stl["carousel-ad_content"]}
                dangerouslySetInnerHTML={{ __html: props.ad_content }}
            ></div>
            <div
                className={classnames(
                    stl["GoShop_btn"],
                    stl["carousel-goshop"],
                    {
                        ["hidden"]: !props.has_skip_btn,
                    },
                )}
            >
                {props.children}
            </div>
        </div>
    );
};

interface INormalNoticeProps extends INoticeProps {}

export const NormalNoticeContent: React.FC<
    React.PropsWithChildren<INormalNoticeProps>
> = (props) => {
    const { topNotice } = props;
    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: topNotice.ad_content ?? "",
                }}
            ></div>
            <div
                className={classnames(
                    "default_layout",
                    stl["has_countdown_container"],
                    "has_countdown_container",
                )}
            >
                {props.children}
            </div>
        </>
    );
};
