// 俩模块，一个展示，一个操作
import React from "react";
import SearchIconClient from "./SearchIconClient";
import LeftMenu from "./LeftMenu";
import stl from "./styles/appBar.module.scss";
import variable from "@/src/styles/variables.module.scss";
import classnames from "classnames";
import UserIconClient from "./UserIconClient";
import FavoriteDrawerClient from "./FavoriteDrawerClient";
import ShoppingCart from "./ShoppingCart";
import AppBarClient, {
    type IProps as IAppbarClientProps,
} from "./AppBarClient";
import HeaderMenuRightTheme from "./HeaderMenuRightTheme";
import FmToolbar from "@/ui-component/basic/FmToolbar";
import FmLink from "@/ui-component/basic/FmLink";
import FmImage from "@/ui-component/basic/FmImage";

export interface IHeaderMenuProps extends IAppbarClientProps {}

const HeaderMenuBar: React.FC<IHeaderMenuProps> = (props) => {
    return (
        <AppBarClient hiddenWhenNoFixed={props.hiddenWhenNoFixed}>
            {/*bar-left: menu + logo */}
            <FmToolbar color="inherit" className={stl["tool-bar-container"]}>
                <LeftMenu>
                    <svg
                        className={stl["fm-menu-icon"]}
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="4786"
                        width="200"
                        height="200"
                    >
                        <path
                            d="M864 249.6h-704c-17.066667 0-32-14.933333-32-32s14.933333-32 32-32h704c17.066667 0 32 14.933333 32 32s-14.933333 32-32 32zM864 544h-704c-17.066667 0-32-14.933333-32-32s14.933333-32 32-32h704c17.066667 0 32 14.933333 32 32s-14.933333 32-32 32zM864 838.4h-704c-17.066667 0-32-14.933333-32-32s14.933333-32 32-32h704c17.066667 0 32 14.933333 32 32 0 19.2-14.933333 32-32 32z"
                            fill={variable.text}
                            p-id="4787"
                        ></path>
                    </svg>
                </LeftMenu>
                <FmLink href="/" className={stl.link}>
                    <FmImage
                        image={"/__firmoo/static/images/logo.svg"}
                        className={stl["fm-image"]}
                        loading={"eager"}
                        defaultImage={"/__firmoo/static/images/logo.png"}
                    />
                </FmLink>
            </FmToolbar>
            {/*bar-right: search + login/out + favorite + cart*/}
            <HeaderMenuRightTheme>
                {/*bar-right: search + login/out + favorite + cart*/}
                <FmToolbar
                    color="inherit"
                    sx={{
                        minHeight: "unset",
                        height: "100%",
                        paddingRight: "0.2rem",
                        flex: 1,
                        justifyContent: "space-between",
                    }}
                >
                    <SearchIconClient>
                        <svg
                            className={stl["bar-right-icon"]}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="4928"
                            width="200"
                            height="200"
                        >
                            <path
                                d="M840.533333 887.466667L603.733333 650.666667l44.8-44.8 236.8 236.8c12.8 12.8 12.8 32 0 44.8-10.666667 10.666667-32 10.666667-44.8 0z"
                                fill={variable.text}
                                p-id="4929"
                            ></path>
                            <path
                                d="M441.6 757.333333C268.8 757.333333 128 616.533333 128 441.6 128 268.8 268.8 128 441.6 128s313.6 140.8 313.6 313.6c2.133333 174.933333-138.666667 315.733333-313.6 315.733333z m0-565.333333C305.066667 192 192 305.066667 192 441.6c0 138.666667 113.066667 249.6 249.6 249.6 138.666667 0 249.6-113.066667 249.6-249.6C693.333333 305.066667 580.266667 192 441.6 192z"
                                fill={variable.text}
                                p-id="4930"
                            ></path>
                        </svg>
                    </SearchIconClient>
                    <UserIconClient>
                        <svg
                            className={classnames(
                                stl["bar-right-icon"],
                                stl["icon-font-size"],
                            )}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="5071"
                            width="200"
                            height="200"
                        >
                            <path
                                d="M896 896H128v-32c0-117.333333 83.2-155.733333 155.733333-187.733333 32-14.933333 64-29.866667 91.733334-51.2-44.8-55.466667-87.466667-153.6-87.466667-230.4C285.866667 228.266667 371.2 128 512 128c138.666667 0 226.133333 102.4 226.133333 266.666667 0 76.8-42.666667 174.933333-89.6 230.4 27.733333 21.333333 59.733333 38.4 93.866667 53.333333 72.533333 34.133333 155.733333 70.4 153.6 185.6V896zM194.133333 832h635.733334c-10.666667-46.933333-51.2-68.266667-113.066667-96-44.8-21.333333-96-44.8-136.533333-87.466667-10.666667-12.8-10.666667-34.133333 2.133333-44.8 40.533333-36.266667 89.6-138.666667 89.6-206.933333 0-59.733333-14.933333-202.666667-162.133333-202.666667-140.8 0-162.133333 125.866667-162.133334 202.666667 0 68.266667 49.066667 172.8 89.6 206.933333 12.8 12.8 14.933333 32 2.133334 44.8-38.4 42.666667-89.6 66.133333-134.4 85.333334-57.6 27.733333-98.133333 49.066667-110.933334 98.133333z"
                                fill={variable.text}
                                p-id="5072"
                            ></path>
                        </svg>
                    </UserIconClient>
                    <FavoriteDrawerClient>
                        <svg
                            className={stl["icon-font-size"]}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="5213"
                            width="200"
                            height="200"
                        >
                            <path
                                d="M757.333333 181.333333c-100.266667-44.8-192-6.4-245.333333 25.6-53.333333-32-145.066667-68.266667-243.2-25.6-115.2 51.2-164.266667 160-130.133333 279.466667 38.4 134.4 298.666667 347.733333 362.666666 398.933333 8.533333 6.4 19.2 6.4 27.733334 0 64-53.333333 337.066667-285.866667 360.533333-398.933333 25.6-121.6-17.066667-228.266667-132.266667-279.466667z m68.266667 260.266667c-23.466667 78.933333-87.466667 149.333333-311.466667 341.333333-226.133333-189.866667-288-260.266667-311.466666-339.2-25.6-87.466667 10.666667-166.4 96-204.8 89.6-40.533333 174.933333 14.933333 204.8 36.266667 6.4 6.4 17.066667 6.4 23.466666 0 34.133333-21.333333 128-72.533333 202.666667-38.4 85.333333 40.533333 121.6 117.333333 96 204.8z"
                                fill={variable.text}
                                p-id="5214"
                            ></path>
                        </svg>
                    </FavoriteDrawerClient>
                    <FmLink
                        href="/index.php?main_page=shopping_cart"
                        className={classnames(
                            stl["bar-right-icon-last"],
                            "bar-right-icon",
                        )}
                    >
                        <ShoppingCart>
                            <svg
                                className={classnames(stl["icon-font-size"])}
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="5355"
                                width="200"
                                height="200"
                            >
                                <path
                                    d="M821.333333 896h-618.666666c-17.066667 0-32-14.933333-32-32V334.933333c0-17.066667 14.933333-32 32-32h618.666666c17.066667 0 32 14.933333 32 32v531.2c-2.133333 14.933333-14.933333 29.866667-32 29.866667z m-584.533333-64H789.333333V366.933333H236.8V832z"
                                    fill={variable.text}
                                    p-id="5356"
                                ></path>
                                <path
                                    d="M718.933333 334.933333h-64a142.933333 142.933333 0 0 0-285.866666 0h-64c0-115.2 93.866667-206.933333 206.933333-206.933333 115.2 0 206.933333 93.866667 206.933333 206.933333z"
                                    fill={variable.text}
                                    p-id="5357"
                                ></path>
                            </svg>
                        </ShoppingCart>
                    </FmLink>
                </FmToolbar>
            </HeaderMenuRightTheme>
        </AppBarClient>
    );
};

export default HeaderMenuBar;
