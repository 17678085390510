import React from "react";
import { ICountDownProps } from "./CountDownClient";
import dynamic from "next/dynamic";
import FmIcon from "@/ui-component/basic/FmIcon";
const CountDownClient = dynamic(() => import("./CountDownClient"), {
    ssr: false,
});
/**
 * 接受3个参数，都是时间戳
 * 1. now: 现在时间
 * 2. lastTime：截至日期
 * 3. startTime: 开始时间
 *
 * @function CountDown
 * @param {Object} props - 组件属性
 * @param {number} props.lastTime - 截至日期时间戳
 * @param {number} props.startTime - 开始时间时间戳
 * @param {Function} props.onClose - 关闭函数
 * @param {React.ReactElement} props.icon - 图标元素
 * @param {string} props.className - 自定义类名
 */
const CountDown: React.FC<ICountDownProps> = (props) => {
    return (
        <CountDownClient {...props}>
            <FmIcon
                icon={props.icon ?? "icon--time"}
                style={{ fontSize: "0.32rem" }}
            />
        </CountDownClient>
    );
};

export default CountDown;
